import React from "react";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import SimpleDateRangePicker from "../../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import { CUSTOM } from "../../../helpers/constant/filters";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: "red",
    marginTop: "5px"
  }
}));

const FilterSelections = ({ dataLists, selectionAddActions, filterValues }) => {
  const {
    handleProviderChange,
    handleCountryChange,
    handlePublisherChange,
    changeSelectedInterval,
    handleGroupByChange,
    handleRuleIdChange,
    handleTagIdChange,
    handleAdvertiserLinkGroupIdChange,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    changeSelectedPeriod,
    handleChannelsChange,
    handleRemarkChange,
    setMissingSubids,
    handleInputChange,
    handleKeyDown,
    clearInputValue
  } = selectionAddActions;

  const {
    publishers,
    providers,
    countries,
    intervals,
    groupByList,
    tagIds,
    advertiserLinkGroupIds,
    ruleIds,
    tagTypes,
    platforms,
    searchEngines,
    channels,
    periods,
    remarks,
    inputValue,
    pubLinkLoading
  } = dataLists;
  const {
    selectedProviders,
    selectedCountries,
    selectedPublishers,
    fromDate,
    toDate,
    selectedInterval,
    selectedGroupBy,
    selectedRuleIds,
    selectedTagIds,
    selectedAdvertiserLinkGroupIds,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedChannels,
    selectedPeriod,
    selectedRemark,
    missingSubids,
    pubLinkIdError
  } = filterValues;

  const classes = useStyles();

  function handleMissingSubidCheck() {
    setMissingSubids(!missingSubids);

    let existingGroupBy = [...selectedGroupBy];
    let existingRemarks = [...selectedRemark];

    if (!existingGroupBy.includes("advertiser_id")) {
      existingGroupBy.push("advertiser_id");
    }
    if (!existingGroupBy.includes("channel")) {
      existingGroupBy.push("channel");
    }
    if (!existingGroupBy.includes("country")) {
      existingGroupBy.push("country");
    }
    if (!existingGroupBy.includes("remarks")) {
      existingGroupBy.push("remarks");
    }
    if (!existingRemarks.includes(2)) {
      existingRemarks.push(2);
    }
    if (!existingRemarks.includes(3)) {
      existingRemarks.push(3);
    }
    if (!existingRemarks.includes(4)) {
      existingRemarks.push(4);
    }
    if (!existingRemarks.includes(5)) {
      existingRemarks.push(5);
    }

    handleRemarkChange(existingRemarks);
    handleGroupByChange(existingGroupBy);
  }

  return (
    <div className="dashboard-report__filters__filter-selects">
      <AutoCompleteSelect
        selected={selectedPeriod}
        data={periods}
        handleChange={changeSelectedPeriod}
        label={"Period"}
        showValueInLabel={false}
        enableMultiple={false}
      />
      {selectedPeriod === CUSTOM && (
        <SimpleDateRangePicker
          startDate={fromDate ? fromDate : null}
          endDate={toDate ? toDate : null}
          handleDateChange={handleDateChange}
        />
      )}
      <AutoCompleteSelect
        selected={selectedInterval}
        data={intervals}
        handleChange={changeSelectedInterval}
        label={"Interval"}
        showValueInLabel={false}
        enableMultiple={false}
      />

      <AutoCompleteSelect
        selected={selectedProviders}
        data={providers}
        handleChange={handleProviderChange}
        label={"Advertisers"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <AutoCompleteSelect
        selected={selectedChannels}
        data={channels}
        handleChange={handleChannelsChange}
        label={"Channel"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedPublishers}
        data={publishers}
        handleChange={handlePublisherChange}
        label={"Publisher"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <AutoCompleteSelect
        selected={selectedRuleIds}
        data={ruleIds}
        handleChange={handleRuleIdChange}
        label={"Rule ID"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <div>
        <AutoCompleteSelect
          error={pubLinkIdError ? true : false}
          selected={selectedTagIds}
          data={tagIds}
          handleChange={handleTagIdChange}
          label={"Pub Link ID"}
          showValueInLabel={false}
          enableMultiple={true}
          withDotsForStatus={true}
          triggerOnKeyDown={true}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          inputValue={inputValue}
          loading={pubLinkLoading}
          clearInputValue={clearInputValue}
        />
        {pubLinkIdError && (
          <div className={classes.errorText}>
            <small>{pubLinkIdError}</small>
          </div>
        )}
      </div>
      <AutoCompleteSelect
        selected={selectedAdvertiserLinkGroupIds}
        data={advertiserLinkGroupIds}
        handleChange={handleAdvertiserLinkGroupIdChange}
        label={"Adv link group ID"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      {/* <AutoCompleteSelect
        selected={selectedPublisherAccounts}
        data={publisherAccounts}
        handleChange={handlePublisherAccountChange}
        label={"Publisher Ad Account"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      /> */}

      <AutoCompleteSelect
        selected={selectedPlatforms}
        data={platforms}
        handleChange={handlePlatformChange}
        label={"Platform"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedSearchEngines}
        data={searchEngines}
        handleChange={handleSearchEngineChange}
        label={"Search Engine"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedTagTypes}
        data={tagTypes}
        handleChange={handleTagTypeChange}
        label={"Traffic Type"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />

      <AutoCompleteSelect
        selected={selectedCountries}
        data={countries}
        handleChange={handleCountryChange}
        label={"Geo"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedRemark}
        data={remarks}
        handleChange={handleRemarkChange}
        label={"Remarks"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedGroupBy}
        data={groupByList}
        handleChange={handleGroupByChange}
        label={"Group By"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <div>
        Missing Channels
        <Checkbox
          checked={missingSubids}
          onChange={handleMissingSubidCheck}
          // inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
    </div>
  );
};

FilterSelections.propTypes = {
  dataLists: PropTypes.object.isRequired,
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired
};

export default FilterSelections;
