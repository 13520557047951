import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
//
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Typography from "@material-ui/core/Typography";
//
import Table from "../../components/Table/Table";
import { Publisher } from "../../services/Publisher";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import TargetFilterBox from "./layouts/TargetFilterBox";
import TargetBulkAction from "./layouts/TargetBulkAction";
import SimpleAccordion from "../../components/Accordion/Accordion";
import { textFieldValid } from "../../helpers/validation";
import TabMenu from "../../components/TabMenu/TabMenu";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import {
  //   updateTargetingForm,
  updateTargetingLists
} from "../../reducers/target/target.action";
import {
  getSelectedFilter,
  getTargetings
} from "../../reducers/target/target.selector";
import { capitalizeFirstLetter } from "../../helpers";
import { copyToClipboard } from "../../helpers/index";
import { LAST_SEVEN_DAYS, PER_DAY } from "../../helpers/constant/filters";
import { LatestHistoryTableModal } from "../../components/HistoryModal/LatestHistoryTableModal";
import ConnectedAdvertiserLinksModal from "./layouts/ConnectedAdvertiserLinksModal";
import { Targeting } from "../../services/Targeting";
import { useFetchLatestHistory } from "./logics/fetchLatestHistory";
import { formatNumberAddComma } from "../../helpers/formatters";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import { TagType } from "../../services/TagType";
import { Grid } from "@material-ui/core";

const List = (props) => {
  const [historyTargId, setHistoryTargId] = useState(null);
  const [historyPage, setHistoryPage] = useState(1);
  const historyLimit = 5;
  const [startFetchHistory, setStartFetchHistory] = useState(false);
  const {
    latestHistoryData,
    isLatestHistoryLoading,
    totalHistoryCount,
    setLatestHistoryData
  } = useFetchLatestHistory({
    id: historyTargId,
    page: historyPage,
    limit: historyLimit,
    startFetch: startFetchHistory,
    setStartFetch: setStartFetchHistory
  });

  const targetings = useSelector(getTargetings);
  const history = useHistory();
  const location = useLocation();
  //getting publisher id in case this page was redirected
  //from publisher listing page
  const getInitialPublisher = useCallback(() => {
    return location?.state?.publisher || "";
  }, [location]);
  const headers = [
    { title: "Pub Link ID", value: "id" },
    { title: "Publisher", value: "Publisher" },
    { title: "Link", value: "link" },
    { title: "Description", value: "tag_description" },
    { title: "Traffic Type", value: "traffic_type" },
    { title: "Status", value: "status" },
    { title: "Active", value: "is_active" },
    { title: "Last Updated", value: "updatedAt" },
    // { title: "Notes", value: "notes" },
    { title: "Daily Cap", value: "daily_cap" },
    { title: "Cap consumption", value: "cap_consumed" },
    { title: "Is Live", value: "is_live", disableOrderBy: true },
    { title: "last Live", value: "last_live", disableOrderBy: true }

    // { title: "CID", value: "client_id" }
  ];
  const headersForArchive = [
    { title: "Pub Link ID", value: "id" },
    { title: "Publisher", value: "Publisher" },
    { title: "Link", value: "link" },
    { title: "Archived At (UTC) ", value: "updatedAt" }
    // { title: "CID", value: "client_id" }
  ];
  //selection index to choose between Archived and Normal Publishers List
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const sections = [
    {
      name: "Publisher Links"
    },
    {
      name: "Archived Publisher Links"
    }
  ];
  let choiceOfWord =
    currentSelectedSectionIndex === 0 ? "archive" : "delete forever";
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  //state for modal
  const [open, setOpen] = useState(false);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [bulkRestoreOpen, setBulkRestoreOpen] = useState(false);
  const [bulkPermanentDeleteOpen, setBulkPermanentDeleteOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  // a state to check if the enable disable api is being called
  const [is_toggling, setIsToggling] = useState(false);

  // status toggle
  const [statusToggleModal, setStatusToggleModal] = useState(false);
  const [selectedTargetingToggleStatus, setSelectedTargetingToggleStatus] =
    useState(null);
  const [tempProviderLinks, setTempProviderLinks] = useState([]);
  const [tempId, setTempId] = useState(null);

  //state for filter box
  const selectedFilter = useSelector(getSelectedFilter);
  const [searchValue, setSearchValue] = useState(selectedFilter.query);
  const [published, setPublished] = useState(selectedFilter.published);
  const [draft, setDraft] = useState(selectedFilter.draft);
  const [is_live, setIsLive] = useState(selectedFilter.isLive);
  const [publisher, setPublisher] = useState(() => {
    let value = getInitialPublisher();
    if (value) {
      return value;
    }
    return selectedFilter.publisher;
  });
  const [trafficType, setTrafficType] = useState(selectedFilter.trafficType);

  const [tag, setTag] = useState(selectedFilter.tagId);
  //state for pagination that will be used as params
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE

  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue.trim();
    }

    if (trafficType) {
      params["traffic_type"] = trafficType;
    }

    if (published) {
      params["status"] = "published";
    }
    if (archived) {
      params["archived"] = true;
    }

    if (draft) {
      params["status"] = "draft";
    }

    if (publisher !== "") {
      params["publisher"] = publisher;
    }
    if (is_live !== null) {
      params["is_live"] = is_live;
    }

    if (is_live !== null) {
      params["is_live"] = is_live;
    }

    if (tag) {
      params["id"] = tag;
    }

    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;

    if (orderBy === "Publisher") {
      params["order_by"] = "publisher_id";
    }

    params["order_direction"] = orderDirection;

    return params;
  }, [
    draft,
    is_live,
    orderDirection,
    orderBy,
    page,
    size,
    published,
    publisher,
    trafficType,
    searchValue,
    archived,
    tag
  ]);

  const fetchTargeting = useCallback(
    async (params = {}) => {
      setLoading(true);
      const trafficTypes = await TagType.fetchTagTypes({
        order_by: "id",
        order_direction: "ASC",
        size: DEFAULT_FETCH_PARAM_SIZE
      }).then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const trafficTypes = [];
          for (let p of data) {
            trafficTypes.push({ label: p.name, value: p.id });
          }
          return trafficTypes;
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      });

      Publisher.getTargeting(params)
        .then((response) => {
          let formatedTargeting = [...response.data.targetings].map(
            (targeting) => {
              targeting.updatedAt =
                targeting.updatedAt !== null
                  ? moment(targeting.updatedAt).utc().local().format("lll")
                  : "-";
              targeting.last_live =
                targeting.last_live !== null
                  ? moment(targeting.last_live).utc().local().format("lll")
                  : "-";
              targeting.status = capitalizeFirstLetter(targeting.status);
              targeting.is_live = targeting.is_live ? "Yes" : "No";
              targeting.cap_consumed = targeting.publisher_channel_search_count
                ? `${formatNumberAddComma(
                    targeting.publisher_channel_search_count.count
                  )} (${targeting.publisher_channel_search_count.date})`
                : 0;
              targeting.traffic_type = targeting.tag_type_id.map((x) => {
                const trafficType = trafficTypes.find(
                  (trafficType) => trafficType.value === x
                );
                return trafficType.label || "";
              });
              return targeting;
            }
          );
          setTotal(response.data.total);
          stableDispatch(updateTargetingLists(formatedTargeting));
          setLoading(false);
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(updateTargetingLists([]));
          toastr.error("Error", "No data found");
          setLoading(false);
        });
    },
    [stableDispatch]
  );

  useEffect(() => {
    fetchTargeting(getRequestParams());
    prevPage.current === page && setSelected([]); //page changed don't make the selected rows empty
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    draft,
    page,
    published,
    publisher,
    archived,
    size,
    orderBy,
    is_live,
    orderDirection,
    getRequestParams,
    fetchTargeting
  ]);

  const getPublisherIDOfTargeting = (id) => {
    let targetingIndex = targetings.findIndex(
      (targeting) => targeting.id === id
    );
    let publisher_id = targetings[targetingIndex].publisher_id;
    return publisher_id;
  };

  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
    setStatusToggleModal(false);
    setBulkDeleteOpen(false);
    setUnArchiveOpen(false);
    setBulkRestoreOpen(false);
    setBulkPermanentDeleteOpen(false);
    setHistoryOpen(false);
    setLatestHistoryData(null);
    // setHistoryPage(1);
  };

  const handleConnectedAdCloseAction = () => {
    setTempProviderLinks([]);
    setTempId(null);
  };

  //publisher id to change through modal
  const [changeID, setChangeID] = useState(null);
  const [selected, setSelected] = React.useState([]);

  /*archived and normal data selection change handler */
  const handleSelectionIndexChange = (index) => {
    //setting Page to 1
    setPage(1);
    setSelected([]);
    // setPublished(false);
    // setDraft(false);
    setCurrentSelectedSectionIndex(index);
    if (index === 0) {
      //normal publisher
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const handleDownloadList = () => {
    const params = getRequestParams();
    params["download"] = true;
    Publisher.downloadList(params)
      .then(({ data, status }) => {
        if (status === 200) {
          const blob = new Blob([data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `data-${new Date().toISOString()}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url); // Cleanup memory
        } else {
          throw new Error(JSON.stringify(data?.message || "Cannot download"));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Cannot download at the moment!");
      });
  };

  const handleEditAction = (id) => {
    // if (!checkDefaultDomainIsSet()) return;
    history.push(`/target/edit/${id}`);
  };

  const handleEditActionNewTab = (id) => {
    const editUrl = `${window.location.origin}/target/edit/${id}`;
    window.open(editUrl);
  };

  const handleDuplicateAction = (id) => {
    history.push(`/target/add?duplicate=${id}`);
  };

  const handleRevReportlLinkAction = (id) => {
    const urlWithParams = `${window.location.origin}/revenuereport?pub_link_id=${id}&interval=${PER_DAY}&period=${LAST_SEVEN_DAYS}&group_by=publisher_id-publisher_channel_id`;
    window.open(urlWithParams, "_blank");
  };

  const onConnectedButtonClick = async (id) => {
    const connectedAdvertiserLinks =
      await Targeting.fetchConnectedAdvertiserLinks(id);
    console.log(connectedAdvertiserLinks);
    if (connectedAdvertiserLinks && connectedAdvertiserLinks?.success) {
      setTempProviderLinks(connectedAdvertiserLinks.data?.advertiserLinksArray);
      setTempId(id);
    }
  };

  const handleDeleteAction = (id) => {
    setChangeID(id);
    setOpen(true);
  };

  const handleHistoryAction = (id, data) => {
    setHistoryTargId(id);
    setHistoryPage(1);
    setHistoryOpen(true);
    setStartFetchHistory(true);
  };

  const handleStatusToggleAction = (id) => {
    let selectedTargeting = targetings.find((targeting) => targeting.id === id);
    // console.log({ selectedTargeting });
    if (selectedTargeting.is_active) {
      setStatusToggleModal(true);
      setSelectedTargetingToggleStatus(selectedTargeting);
    } else {
      handleStatusToggle(
        selectedTargeting.id,
        selectedTargeting.is_active,
        selectedTargeting
      );
    }
  };
  const deleteTargeting = () => {
    Publisher.deleteTargeting(changeID)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Archived Successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", "Failed to archive");
        setOpen(false);
      });
  };

  const unArchiveTargeting = () => {
    let params = {
      archive: false
    };
    let dataToSend = {};
    dataToSend.publisher_id = getPublisherIDOfTargeting(changeID);
    Publisher.updateTargeting(changeID, dataToSend, params)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targeting un-archived!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to un-archive the targeting");
      });
  };

  const handleBulkRestoreAction = () => {
    if (selected.length > 0) {
      setBulkRestoreOpen(true);
    } else {
      toastr.warning("Oops", "No Targetings selected");
    }
  };

  const handleBulkPermanentDelete = () => {
    if (selected.length > 0) {
      setBulkPermanentDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const handleRestoreAction = (id) => {
    setChangeID(id);
    setUnArchiveOpen(true);
  };

  const handleDeleteForever = () => {
    let params = {
      permanent: true
    };
    Publisher.deleteTargeting(changeID, params)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Targeting removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", "Not able to remove at the moment");
        setOpen(false);
      });
    setChangeID(null);
    setOpen(false);
  };

  const handleCopyTagToClipboard = (id) => {
    let targeting = targetings.find((targeting) => targeting.id === id);
    copyToClipboard(targeting.link);
    toastr.success("Success", "Link copied!");
  };

  //action list for archived table
  const actionsForArchive = [
    // {
    //   name: "deleteForever",
    //   action: handleDeleteAction,
    //   icon: "deleteForever"
    // },
    {
      name: "restore",
      action: handleRestoreAction,
      icon: "restore"
    }
  ];

  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "deleteIcon"
    },
    {
      name: "duplicate",
      action: handleDuplicateAction,
      icon: "duplicateIcon"
    },
    {
      name: "revenueReportLink",
      action: handleRevReportlLinkAction,
      icon: "MonetizationIcon"
    },
    {
      name: "connectedAdvertiserLinks",
      action: onConnectedButtonClick,
      icon: "connectedAdvertiserLinks"
    },
    {
      name: "history",
      action: handleHistoryAction,
      icon: "HistoryIcon"
    }
  ];
  const icon = <TargetIcon fontSize="inherit" color="primary" />;

  const handleBulkDeleteAction = () => {
    if (selected.length > 0) {
      setBulkDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Targeting selected");
    }
  };

  const bulkDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkDeleteTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetings archived");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to archive targetings");
      });
    setBulkDeleteOpen(false);
    setSelected([]);
  };

  const bulkPermanentDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkPermanentDeleteTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetingss permanently deleted!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to permanently delete targetings!");
      });
    setSelected([]);
  };

  const bulkRestore = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.restoreTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetings restored");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to restore targetings");
      });
    setSelected([]);
  };

  const handleStatusToggle = (
    id = selectedTargetingToggleStatus.id,
    currentStatus = selectedTargetingToggleStatus.is_active,
    data = selectedTargetingToggleStatus
  ) => {
    let dataToSend = {};
    dataToSend.is_active = !currentStatus;
    dataToSend.publisher_id = data.publisher_id;
    setIsToggling(true);
    Publisher.updateTargeting(data.id, dataToSend)
      .then((response) => {
        if (response.success) {
          /*change the targeting status from redux store targeting lists*/
          let tempTargetings = [...targetings];
          /*get the index of the targeting from the targetings arra */
          let changedIndex = tempTargetings.findIndex(
            (targeting) => targeting.id === data.id
          );
          /*change the status */
          tempTargetings[changedIndex].is_active = dataToSend.is_active;
          /*dispatch to store */
          dispatch(updateTargetingLists(tempTargetings));
          toastr.success("Success", "Status updated!");
          handleModalClose();
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to update");
      })
      .finally(() => {
        setIsToggling(false);
      });
  };

  const togglePublisherLinkStatusModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure you want to disable this publisher link? Disabling
          publisher link will disable all the associated rules too.
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStatusToggle}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const deleteTargetingModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure you want to {choiceOfWord}?
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //current Selection is 0 i.e normal data, archive
              if (currentSelectedSectionIndex === 0) deleteTargeting();
              else handleDeleteForever();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const bulkChangeStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "activate") {
        changeType = true;
      }
      if (type === "de-activate") {
        changeType = false;
      }
      Publisher.bulkUpdateTargetings(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchTargeting(getRequestParams());
            toastr.success("Success", "Status updated");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update targetings status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops", "Nothing Selected");
    }
  };

  const bulkChangePublishStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "draft") {
        changeType = "draft";
      }
      if (type === "published") {
        changeType = "published";
      }
      Publisher.bulkUpdatePublishStatusTargetings(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchTargeting(getRequestParams());
            toastr.success("Success", "Status updated");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update targetings status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops", "Nothing Selected");
    }
  };

  const bulkDeleteTargetingModal = (
    <ConfirmBox
      message={`Are you sure want to archive ${selected.length} items?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        bulkDelete();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const bulkPermanentDeletePublisherModal = (
    <ConfirmBox
      message={`Are you sure want to permanently delete ${selected.length} items?`}
      confirmAction={() => {
        bulkPermanentDelete();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const unArchiveModal = (
    <ConfirmBox
      message={`Are you sure you want to un-archive this targeting?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        unArchiveTargeting();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const bulkRestoreTargetingModal = (
    <ConfirmBox
      message={`Are you sure want to restore ${selected.length} items?`}
      confirmAction={() => {
        bulkRestore();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const openTargetFormPage = () => {
    history.push("/target/add");
  };

  return (
    <div className="targetings-container">
      <div className="targetings-container__header">
        <ContentHeader icon={icon} title="Publisher Links List" />
        <div className="targetings-container__addBtn">
          <Button
            onClick={openTargetFormPage}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="targetings-container__filter-box">
        <SimpleAccordion
          header="Filters"
          key="targeting-filterbox"
          expanded={
            selectedFilter.publisher ||
            selectedFilter.query ||
            selectedFilter.isLive !== null ||
            selectedFilter.tagId ||
            selectedFilter.published ||
            selectedFilter.draft
              ? true
              : false
          }
        >
          <TargetFilterBox
            setSearchValue={setSearchValue}
            published={published}
            setPublished={setPublished}
            draft={draft}
            setDraft={setDraft}
            is_live={is_live}
            setIsLive={setIsLive}
            publisher={publisher}
            setPublisher={setPublisher}
            trafficType={trafficType}
            setTrafficType={setTrafficType}
            setSelected={setSelected}
            archive={currentSelectedSectionIndex === 1}
            tag={tag}
            setTag={setTag}
          />
        </SimpleAccordion>
        <SimpleAccordion header="Bulk Actions">
          <TargetBulkAction
            handleBulkDeleteAction={handleBulkDeleteAction}
            handleStatusChange={bulkChangeStatus}
            handlePublishStatusChange={bulkChangePublishStatus}
            archive={currentSelectedSectionIndex === 1}
            handleBulkRestore={handleBulkRestoreAction}
            handleBulkPermanentDelete={handleBulkPermanentDelete}
          />
        </SimpleAccordion>
      </div>

      <Grid container alignItems="center" spacing={2}>
        {/* Left Section - Tabs */}
        <Grid item xs={9}>
          <TabMenu
            sections={sections}
            setCurrentSelectedSectionIndex={handleSelectionIndexChange}
            currentSelectedSectionIndex={currentSelectedSectionIndex}
          />
        </Grid>

        {/* Right Section - Download Button */}
        <Grid item xs={3} container justifyContent="flex-end">
          <Button
            style={{ margin: "20px" }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleDownloadList}
            className="download-btn"
          >
            Download
          </Button>
        </Grid>
      </Grid>

      <Table
        headers={
          currentSelectedSectionIndex === 0 ? headers : headersForArchive
        }
        rowValues={targetings}
        actions={
          currentSelectedSectionIndex === 0 ? actions : actionsForArchive
        }
        selected={selected}
        setSelected={setSelected}
        tableType={
          currentSelectedSectionIndex === 0
            ? "targetings"
            : "targetings--archive"
        }
        handleStatusToggle={is_toggling ? () => {} : handleStatusToggleAction}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        total={total}
        isLoading={loading}
        rowsPerPageOptions={[10, 50, 100, 150, 200]}
        handleEditAction={handleEditActionNewTab}
        hoverButtonAction={handleCopyTagToClipboard}
        hoverButtonMsg={"Copy Link"}
      />
      {statusToggleModal && togglePublisherLinkStatusModal}
      {open && deleteTargetingModal}
      {bulkDeleteOpen && bulkDeleteTargetingModal}
      {unArchiveOpen && unArchiveModal}
      {bulkRestoreOpen && bulkRestoreTargetingModal}
      {bulkPermanentDeleteOpen && bulkPermanentDeletePublisherModal}
      {historyOpen && (
        <LatestHistoryTableModal
          handleModalClose={handleModalClose}
          activityLog={latestHistoryData}
          tableType="PublisherLatestHistory"
          historyTargId={historyTargId}
          historyPage={historyPage}
          setHistoryPage={setHistoryPage}
          historyLimit={historyLimit}
          totalHistoryCount={totalHistoryCount}
          isLatestHistoryLoading={isLatestHistoryLoading}
          setStartFetchHistory={setStartFetchHistory}
        />
      )}
      {tempProviderLinks && tempProviderLinks.length > 0 && (
        <ConnectedAdvertiserLinksModal
          targetLinkId={tempId}
          providerLinkObj={tempProviderLinks}
          closeAction={handleConnectedAdCloseAction}
        />
      )}
    </div>
  );
};

export default List;
