import React from "react";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../../../components/AutoCompleteSelect/AutoCompleteSelect";
import SimpleDateRangePicker from "../../../../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import {
  LINK_PLATFORM,
  SEARCH_ENGINE,
  TAG_TYPE,
  CUSTOM,
  COUNTRY
} from "../../../../../helpers/constant/filters";
const FilterSelections = ({
  dataLists,
  selectionAddActions,
  filterValues,
  displayColumns
}) => {
  const {
    handleCountryChange,
    handleGroupByChange,
    changeSelectedInterval,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    changeSelectedPeriod
  } = selectionAddActions;

  const {
    countries,
    intervals,
    groupByList,
    tagTypes,
    platforms,
    searchEngines,
    periods
  } = dataLists;

  const {
    selectedCountries,
    selectedInterval,
    selectedGroupBy,
    fromDate,
    toDate,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedPeriod
  } = filterValues;

  return (
    <div className="dashboard-report__filters__filter-selects">
      <AutoCompleteSelect
        selected={selectedPeriod}
        data={periods}
        handleChange={changeSelectedPeriod}
        label={"Period"}
        showValueInLabel={false}
        enableMultiple={false}
      />
      {selectedPeriod === CUSTOM && (
        <SimpleDateRangePicker
          startDate={fromDate ? fromDate : null}
          endDate={toDate ? toDate : null}
          handleDateChange={handleDateChange}
        />
      )}
      <AutoCompleteSelect
        selected={selectedInterval}
        data={intervals}
        handleChange={changeSelectedInterval}
        label={"Interval"}
        showValueInLabel={false}
        enableMultiple={false}
      />

      {displayColumns.includes(LINK_PLATFORM) && (
        <AutoCompleteSelect
          selected={selectedPlatforms}
          data={platforms}
          handleChange={handlePlatformChange}
          label={"Platform"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
      )}

      {displayColumns.includes(SEARCH_ENGINE) && (
        <AutoCompleteSelect
          selected={selectedSearchEngines}
          data={searchEngines}
          handleChange={handleSearchEngineChange}
          label={"Search Engine"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
      )}
      {displayColumns.includes(TAG_TYPE) && (
        <AutoCompleteSelect
          selected={selectedTagTypes}
          data={tagTypes}
          handleChange={handleTagTypeChange}
          label={"Traffic Types"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
      )}

      {displayColumns.includes(COUNTRY) && (
        <AutoCompleteSelect
          selected={selectedCountries}
          data={countries}
          handleChange={handleCountryChange}
          label={"Country"}
          showValueInLabel={true}
          enableMultiple={true}
        />
      )}

      {groupByList.length > 0 && (
        <AutoCompleteSelect
          selected={selectedGroupBy}
          data={groupByList}
          handleChange={handleGroupByChange}
          label={"Group By"}
          showValueInLabel={false}
          enableMultiple={true}
        />
      )}
    </div>
  );
};

FilterSelections.propTypes = {
  dataLists: PropTypes.object.isRequired,
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired
};

export default FilterSelections;
