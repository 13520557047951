import { useEffect, useState } from "react";
import { User } from "../../../services/ReportingUser";

import { useIsMounted } from "../../../hooks/useIsMounted";
import { useFormTouched } from "../../../hooks/useFormTouched";
import {
  INITIAL_SEARCHES,
  FOLLOWON_SEARCHES,
  RPC,
  RPMM,
  RPM,
  AD_COVERAGE,
  CLICKS,
  DATE,
  COMPANY_NAME,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  MONETIZED_CTR,
  INVALID_TRAFFIC,
  FILTERED_REASON,
  PUBLISHER_CHANNEL_ID,
  OVERCAPPED_TRAFFIC
  // ESTIMATED_REVENUE
} from "../../../helpers/constant/filters";
import { removeValueFromArray } from "../../../helpers";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
const GEO = "country";
const SERVER_SEARCHES_RPM = "ssrpm";
const SERVER_TOTAL_SEARCHES = "search_count";
const MONETIZED_SEARCHES = "monetized_searches";
const TOTAL_SEARCHES = "adv_search_count";
const TOTAL_SEARCHES_V2 = "advertiser_search_count";
const PUBLISHER_REVENUE = "publisher_revenue";
const SERVER_AD_COVERAGE = "ad_coverage_server_percentage";
export const columnsList = [
  COMPANY_NAME,
  //   "targeting_id",
  PUBLISHER_CHANNEL_ID,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  GEO,
  //   "server_search_count",
  SERVER_TOTAL_SEARCHES,
  INVALID_TRAFFIC,
  //   TOTAL_SEARCHES,
  TOTAL_SEARCHES_V2,
  MONETIZED_SEARCHES,
  MONETIZED_CTR,
  CLICKS,
  AD_COVERAGE,
  //   "net_revenue",
  PUBLISHER_REVENUE,
  // ESTIMATED_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SERVER_SEARCHES_RPM,
  SERVER_AD_COVERAGE,
  FILTERED_REASON
];

export const getLabel = (field) => {
  switch (field) {
    case DATE:
      return "Date";
    case FILTERED_REASON:
      return "Filtered Reason";
    case INVALID_TRAFFIC:
      return "Invalid Traffic";
    case OVERCAPPED_TRAFFIC:
      return "Overcapped Traffic";
    case COMPANY_NAME:
      return "Company Name";
    // case "targeting_id":
    //   return "Channel";
    case PUBLISHER_CHANNEL_ID:
      return "Channel";
    case SEARCH_ENGINE:
      return "Search Engine";
    case TAG_TYPE:
      return "Traffic Type";
    case LINK_PLATFORM:
      return "Platform";
    case GEO:
      return "GEO";
    case SERVER_TOTAL_SEARCHES:
      return "Server Total Searches";
    // case 'server_search_count':
    //     return "Server Total searches";
    // case TOTAL_SEARCHES:
    //     return 'Total Searches';
    case TOTAL_SEARCHES_V2:
      return "Total Searches";
    case MONETIZED_SEARCHES:
      return "Monetized Searches";
    case CLICKS:
      return "Clicks";
    case AD_COVERAGE:
      return "Ad Coverage";
    // case "net_revenue":
    //   return "Publisher Revenue";
    case PUBLISHER_REVENUE:
      return "Publisher Revenue";
    case RPM:
      return "RPM";
    case RPMM:
      return "Monetized RPM";
    case RPC:
      return "RPC";
    case FOLLOWON_SEARCHES:
      return "Follow-on Searches";
    case INITIAL_SEARCHES:
      return "Initial Searches";
    case MONETIZED_CTR:
      return "Monetized CTR";
    case SERVER_SEARCHES_RPM:
      return "Server Searches RPM";
    case SERVER_AD_COVERAGE:
      return "Ad Coverage Server";
    default:
      return field;
  }
};

export const useDisplayColumnsList = (displayColumns, userId) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(true); //form selection is valid
  const history = useHistory();
  const isMounted = useIsMounted();
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });
  useEffect(() => {
    if (displayColumns.length > 0) {
      setSelectedColumns(displayColumns);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [displayColumns]);

  const removeFromSelectedColumn = (column) => {
    handleFormTouched();
    const currentColumns = [...selectedColumns];
    return setSelectedColumns(removeValueFromArray(currentColumns, column));
  };

  const addToSelectedColumn = (column) => {
    handleFormTouched();
    const currentColumns = [...selectedColumns];
    return setSelectedColumns([...currentColumns, column]);
  };

  const saveDisplayColumns = async () => {
    setIsSaving(true);
    const dataToSend = {
      data: addChannel(selectedColumns)
    };
    try {
      const savedDisplayColumns = await User.postPublisherDisplayColumns(
        userId,
        dataToSend
      );

      if (savedDisplayColumns.success) {
        clearFormTouched();
        if (isMounted()) {
          setIsSaving(false);
          setIsEditing(true);
        }
        toastr.success("Success", "Display columns saved successfully");
      } else {
        throw new Error(
          savedDisplayColumns.error.message || "Unable to save display columns"
        );
      }
    } catch (error) {
      if (isMounted()) {
        setIsSaving(false);
      }
      console.trace(error.message);
      toastr.warning(
        "Oops!",
        error.message || "Unable to save display columns"
      );
    }
  };

  const validateDisplayColumns = () => {
    if (selectedColumns.length === 0) {
      toastr.warning("Oops!", "Please select requird values");
      return false;
    }
    if (
      !selectedColumns.includes(PUBLISHER_REVENUE) &&
      !selectedColumns.includes(MONETIZED_SEARCHES) &&
      !selectedColumns.includes(TOTAL_SEARCHES)
    ) {
      toastr.warning("Oops!", "Please select required values");
      return false;
    }
    return true;
  };

  /**
   * Function to check if targeting_id exists in selected column then take respective acction
   * @param {Array<String>} columns
   * @return {Array}
   */
  const addChannel = (columns) => {
    let data = [...columns];
    // as we need chanel if there is target_id present and vice versa
    const channel_index = data.indexOf("channel");
    // if there is targeting id in column list channel is not in it then add
    // it there is is no targeting id but channel in column list then remove channel
    if (data.includes(PUBLISHER_CHANNEL_ID)) {
      if (channel_index === -1) {
        data.push("channel");
      }
    } else {
      if (channel_index > -1) {
        data.splice(channel_index, 1);
      }
    }
    return data;
  };

  const updateDisplayColumns = async () => {
    setIsSaving(true);
    const dataToSend = {
      data: addChannel(selectedColumns)
    };

    try {
      const savedDisplayColumns = await User.updatePublisherDisplayColumns(
        userId,
        dataToSend
      );
      if (savedDisplayColumns.success) {
        clearFormTouched();
        if (isMounted()) {
          setIsSaving(false);
        }
        toastr.success("Success", "Display columns updated successfully");
      } else {
        throw new Error(
          savedDisplayColumns.error.message ||
            "Unable to update display columns"
        );
      }
    } catch (error) {
      if (isMounted()) {
        setIsSaving(false);
      }
      console.trace(error.message);
      toastr.warning(
        "Oops!",
        error.message || "Unable to update display columns"
      );
    }
  };

  const submitSelectedColumns = () => {
    setIsValid(true);
    if (!validateDisplayColumns()) {
      setIsValid(false);
      return;
    }
    if (!isEditing) {
      saveDisplayColumns();
    } else {
      updateDisplayColumns();
    }
  };

  const handleCancel = () => {
    history.push("/usermanagement");
  };

  return {
    selectedColumns,
    columnsList,
    removeFromSelectedColumn,
    addToSelectedColumn,
    submitSelectedColumns,
    isSaving,
    formTouched,
    getLabel,
    handleCancel,
    isValid
  };
};
