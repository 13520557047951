import React from "react";
import {
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  IconButton,
  FormHelperText,
  Grid
} from "@material-ui/core";

// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import RelatedProviderLinkIcon from "../RelatedProviderLinkIcon";
import RelatedProviderLinkModal from "../RelatedProviderLinkModal";
import { useModal } from "../../../hooks/useModal";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { QualityScoreDropDown } from "../../../helpers/constant/quality-score";

const LinkBox = ({
  linkValues,
  handleLinkFormValueChange,
  linkIndex,
  handleDeleteAction,
  linkErrors,
  platforms,
  searchEngines,
  tagTypes
}) => {
  const [modalOpen, openModal, closeModal] = useModal();

  //form values

  const link = linkValues?.link || "";
  const linkID = linkValues?.id || "";
  const noOfRules = linkValues?.no_of_rules || 0;
  const noOfActiveRules = linkValues?.no_of_active_rules || 0;
  const disabled = linkValues?.disabled || false;
  const targetObj = linkValues?.targetObj || [];
  const description = linkValues?.description || "";
  const searchEngineId = linkValues?.search_engine_id || "";
  const platformId = linkValues?.platform_id || "";
  const tagTypeId = linkValues?.tag_type_id || "";
  const qualityScore = linkValues?.quality_score || "";
  const link_source_identifier = linkValues?.link_source_identifier;
  const link_source_identifier_value =
    linkValues?.link_source_identifier_value || "?";

  return (
    <div style={{ borderTop: "3px solid gray", padding: "10px 0" }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item sm={12} md={1} lg={1}>
          <FormControl fullWidth>
            <TextField
              label="ID"
              variant="outlined"
              name="link-id"
              type="number"
              value={linkID}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={9} lg={9}>
          <FormControl fullWidth>
            <TextField
              label="Link"
              variant="outlined"
              name="link"
              value={link}
              onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
            />
            {linkErrors?.link && (
              <FormHelperText className="error-text">
                Please make sure the link is valid, includes [Q] and uses only [
                ] Brackets.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} md={2} lg={2}>
          <RelatedProviderLinkIcon
            openModal={openModal}
            noOfRules={noOfRules}
            noOfActiveRules={noOfActiveRules}
          />
          <FormControlLabel
            control={
              <Tooltip title={`${true ? "Disable Link" : "Enable Link"}`}>
                <Switch
                  checked={!disabled}
                  onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
                  name="disabled"
                />
              </Tooltip>
            }
          />
          <Tooltip title="Delete Link" className="provider-link__close">
            <IconButton
              color={true ? "secondary" : "default"}
              onClick={() => {
                handleDeleteAction(linkIndex);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ paddingTop: "5px" }}>
        <Grid item sm={12} md={3} lg={3}>
          <FormControl fullWidth>
            <TextField
              label="Link Source Identifier"
              variant="outlined"
              name="link-source-identifier"
              type="string"
              value={link_source_identifier}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={9} lg={9}>
          <FormControl fullWidth>
            <TextField
              label="Channel Value"
              variant="outlined"
              name="channel-value"
              type="string"
              value={link_source_identifier_value}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={searchEngineId}
                data={searchEngines || []}
                handleChange={(value) =>
                  handleLinkFormValueChange(
                    {
                      target: {
                        name: "search_engine",
                        value
                      }
                    },
                    linkIndex
                  )
                }
                label={"Search Engine"}
                enableMultiple={false}
                cannotSelectDisabled={false}
              />
              {linkErrors?.search_engine_id && (
                <FormHelperText className="error-text">
                  Please make sure to select the search engine
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={qualityScore}
                data={QualityScoreDropDown}
                handleChange={(value) => {
                  handleLinkFormValueChange(
                    {
                      target: {
                        name: "quality_score",
                        value
                      }
                    },
                    linkIndex
                  );
                }}
                label={"Quality Score"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                showValueInLabel={false}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={tagTypeId}
                data={tagTypes || []}
                handleChange={(value) =>
                  handleLinkFormValueChange(
                    {
                      target: {
                        name: "tag_type",
                        value
                      }
                    },
                    linkIndex
                  )
                }
                label={"Traffic Types"}
                enableMultiple={false}
                cannotSelectDisabled={false}
              />
              {linkErrors?.tag_type_id && (
                <FormHelperText className="error-text">
                  Please make sure to select the Traffic Type
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={platformId}
                data={platforms || []}
                handleChange={(value) =>
                  handleLinkFormValueChange(
                    {
                      target: {
                        name: "platform",
                        value
                      }
                    },
                    linkIndex
                  )
                }
                label={"Platforms"}
                enableMultiple={false}
                cannotSelectDisabled={false}
              />
              {linkErrors?.platform_id && (
                <FormHelperText className="error-text">
                  Please make sure to select the Platform
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          {/* <MoreVertIcon className="vertical-divider" />
          <MoreHorizIcon className="horizontal-divider" /> */}
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <FormControl fullWidth>
            <TextField
              label="Description"
              variant="outlined"
              name="description"
              value={description}
              onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
      <RelatedProviderLinkModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        targetObj={targetObj}
        linkID={linkID}
      />
    </div>
  );
};

export default LinkBox;
