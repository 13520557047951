import React from "react";
import { SHOW_ADD_BUTTON } from "./logic/formUI.logic";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import ProviderLinkBox from "./layouts/ProviderLinkBox";

const ProviderLinksFormContainer = ({
  tagTypes,
  platforms,
  searchEngines,
  providers,
  formUILogic,
  getProviderLinks,
  handleDownloadList
}) => {
  const { currentPage, pageData, showAddForm, showAddButton } = formUILogic;
  if (currentPage === SHOW_ADD_BUTTON) {
    return (
      <>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between"
          }}
        >
          <Button
            onClick={showAddForm}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add New Link
          </Button>

          <Button
            onClick={handleDownloadList}
            variant="contained"
            size="medium"
            color="primary"
          >
            Download
          </Button>
        </div>
      </>
    );
  }

  return (
    <ProviderLinkBox
      pageData={pageData}
      currentPage={currentPage}
      showAddButton={showAddButton}
      tagTypes={tagTypes}
      platforms={platforms}
      searchEngines={searchEngines}
      providers={providers}
      getProviderLinks={getProviderLinks}
    />
  );
};

export default ProviderLinksFormContainer;
