import Api from "../api";

class Provider {
  addProvider = async (data) => {
    const { body } = await Api.post("provider", data);
    return body;
  };

  fetchProvider = async (id) => {
    const { body } = await Api.get(`provider/${id}`);
    return body;
  };

  fetchProviderLinks = async (
    id,
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`provider/${id}/links`, null, params);
    return body;
  };

  fetchAllProviderLinks = async () => {
    const { body } = await Api.get("providers/links");
    return body;
  };

  saveProviderLinks = async (id, data) => {
    const { body } = await Api.post(`provider/${id}/links`, data);
    return body;
  };

  fetchProviderLinksForDropdown = async (data = {}) => {
    const { body } = await Api.post(`provider_links`, JSON.stringify(data));
    return body;
  };

  changeProviderLinkStatus = async (id, data) => {
    const { body } = await Api.put(`provider/${id}/links/update-status`, data);
    return body;
  };

  deleteProviderLink = async (linkId) => {
    const { body } = await Api.destroy(`provider/link/${linkId}`);
    return body;
  };

  updateProvider = async (id, data, params = {}) => {
    const { body } = await Api.put(`provider/${id}`, data, params);
    return body;
  };

  bulkDeleteProviders = async (data) => {
    const { body } = await Api.destroy(`providers`, data);
    return body;
  };

  bulkUpdateProviders = async (type, data) => {
    const { body } = await Api.put(`providers?status=${type}`, data);
    return body;
  };

  restoreProviders = async (data) => {
    const { body } = await Api.put(`providers?deleted=false`, data);
    return body;
  };

  fetchProviders = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`providers`, null, params);
    return body;
  };

  fetchProviderLinksList = async (
    params = { order_by: "id", order_direction: "ASC" },
    cancelToken = null
  ) => {
    const { body } = await Api.get(
      "provider/all/links",
      null,
      params,
      cancelToken
    );
    return body;
  };

  changeProviderLinkReusableStatus = async (link_id, reusable) => {
    const { body } = await Api.put(`provider-link/status/${link_id}`, {
      reusable
    });
    return body;
  };

  deleteProvider = async (id, params = {}) => {
    const { body } = await Api.destroy(`provider/${id}`, {}, params);
    return body;
  };

  getIdenticalLinks = async (id, data, includeSelf = false, link_id = null) => {
    const { body } = await Api.post(
      `provider/${id}/identicallinks?link_id=${link_id ? link_id : ""}${
        includeSelf ? "&include_self=true" : ""
      }`,
      data
    );
    return body;
  };

  getRelatedLinkStats = async (ruleId) => {
    const santizedRuleId = encodeURIComponent(ruleId.join(","));
    const params = { rule_ids: santizedRuleId };
    const { body } = await Api.getGCP(
      `api/v1/bigquery/advertiser-related-links-stats`,
      null,
      params
    );
    return body;
  };

  fetchLatestHistory = async (id, queryParamString = "") => {
    const url = `provider-link/get-latest-history/${id}${queryParamString}`;
    const { body } = await Api.get(url);
    return body;
  };
  downloadList = async (params) => {
    const response = await Api.get("provider/all/links", null, params, null);
    return response;
  };
}
// eslint-disable-next-line
export default new Provider();
