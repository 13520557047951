import { useHistory } from "react-router-dom";
import { useModal } from "../../../hooks/useModal";
import { useState } from "react";
import { Provider } from "../../../services/Provider";
import { toastr } from "react-redux-toastr";
import { getActiveRules } from "../../ProviderLinks/helpers";
import { LAST_SEVEN_DAYS, PER_DAY } from "../../../helpers/constant/filters";
import { useFetchLatestHistory } from "./fetchLatestHistory";
export const useProviderLinkTableLogic = ({
  updateProviderLinks,
  getProviderLinks,
  showEditForm,
  showDuplicateForm
}) => {
  // latest history
  const [historyTargId, setHistoryTargId] = useState(null);
  const [historyPage, setHistoryPage] = useState(1);
  const historyLimit = 5;
  const [startFetchHistory, setStartFetchHistory] = useState(false);
  const {
    latestHistoryData,
    isLatestHistoryLoading,
    totalHistoryCount,
    setLatestHistoryData
  } = useFetchLatestHistory({
    id: historyTargId,
    page: historyPage,
    limit: historyLimit,
    startFetch: startFetchHistory,
    setStartFetch: setStartFetchHistory
  });
  const [historyOpen, setHistoryOpen] = useState(false);

  const [relatedModalOpen, openRelatedModal, closeRelatedModal] = useModal();
  const [relatedModalData, setRelatedModalData] = useState(null);

  const [warningModalOpen, openWarningModal, closeWarningModal] = useModal();
  const [warningModalData, setWarningModalData] = useState(null);

  // a state to check if the enable disable api is being called
  const [isLinkStatusToggling, setIsLinkStatusToggling] = useState(false);

  const [
    confirmDeleteModalOpen,
    openConfirmDeleteModal,
    closeConfirmDeleteModal
  ] = useModal();
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null);

  const history = useHistory();
  const headers = [
    { title: "ID", value: "id" },
    { title: "Link", value: "link" },
    { title: "Advertiser", value: "provider_id" },
    { title: "Description", value: "description" },
    { title: "Traffic Type", value: "tag_type_id" },
    { title: "Search Engine", value: "search_engine_id" },
    { title: "Platform", value: "platform_id" },
    { title: "Daily Cap", value: "daily_cap" },
    { title: "Remaining Cap", value: "remaining_cap" },
    { title: "Cap Consumption", value: "cap_consumed", disableOrderBy: true },
    { title: "Quality Score", value: "quality_score" },
    { title: "Is Live", value: "is_live", disableOrderBy: true },
    { title: "Link Last Live", value: "last_live" },
    { title: "Created At(UTC)", value: "createdAt" },
    { title: "Status", value: "disabled" },
    {
      title: "Ready to use",
      value: "reusable",
      rowFunction: changeReusableStatus
    }
  ];

  function openFormPage(id, data) {
    const provider_id = data?.provider.id || "";
    if (provider_id) {
      history.push({
        pathname: "/advertisers/links/add",
        state: {
          provider: provider_id
        }
      });
    }
  }

  function closeRelatedModalHandler() {
    setRelatedModalData(null);
    closeRelatedModal();
  }

  function closeWarningModalHandler() {
    setWarningModalData(null);
    closeWarningModal();
  }

  function onRelatedButtonClick(id, data) {
    if (data.no_of_rules > 0) {
      setRelatedModalData({
        linkID: data.id,
        targetObj: data.targetObj
      });
    }
    openRelatedModal();
  }

  async function changeReusableStatus(link_id, reusable_status) {
    try {
      const response = await Provider.changeProviderLinkReusableStatus(
        link_id,
        reusable_status
      );
      if (response.success) {
        updateProviderLinks(link_id, "reusable", !reusable_status);
        toastr.success("Success", "Reusable Status updated");
      }
    } catch (error) {
      toastr.error(
        "Error",
        "Some Error Ocurred while Changing Reusable Status"
      );
    }
  }

  async function deleteProviderLink() {
    try {
      const response = await Provider.deleteProviderLink(
        confirmDeleteModalData
      );
      if (response.success) {
        closeConfirmDeleteModalHandler();
        toastr.success("Success", "Advertiser Link Deleted");
        getProviderLinks();
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      closeConfirmDeleteModalHandler();
      console.trace(error.message);
      toastr.info("Oops!", error.message);
    }
  }

  function closeConfirmDeleteModalHandler() {
    setConfirmDeleteModalData(null);
    closeConfirmDeleteModal();
  }

  function onDeleteButtonClick(id, data) {
    if (parseInt(data?.no_of_rules) > 0) {
      toastr.info("Oops", "Link is currently used in some targeting rule!");
      return;
    }
    setConfirmDeleteModalData(id);
    openConfirmDeleteModal();
  }

  function onDeleteConfirmed() {
    closeConfirmDeleteModal();
    deleteProviderLink();
  }

  const handleStatusToggle = async (id, currentStatus, data) => {
    if (data?.no_of_active_rules && parseInt(data?.no_of_active_rules) > 0) {
      setWarningModalData(getActiveRules(data?.targetObj || []));
      openWarningModal();
      return;
    }
    try {
      const {
        link,
        search_engine_type,
        p_sid,
        searchq_val,
        n_val,
        sub_id_val,
        description,
        click_id_val,
        link_source_identifier,
        link_source_identifier_value
      } = data;
      const provider_id = data?.provider?.id;
      if (!provider_id) {
        return;
      }
      const dataToSend = [
        {
          id,
          provider_id: data.provider.id,
          link,
          search_engine_type,
          p_sid,
          searchq_val,
          n_val,
          sub_id_val,
          description,
          click_id_val,
          disabled: !currentStatus,
          platform_id: data.platform.id,
          search_engine_id: data.search_engine.id,
          tag_type_id: data.tag_type.id,
          link_source_identifier,
          link_source_identifier_value
        }
      ];

      setIsLinkStatusToggling(true);

      // const savedData = await Provider.saveProviderLinks(
      //   provider_id,
      //   dataToSend
      // );
      const savedData = await Provider.changeProviderLinkStatus(
        provider_id,
        dataToSend
      );
      if (savedData.success) {
        updateProviderLinks(id, "disabled", dataToSend[0].disabled);
        toastr.success("Success!", "Status updated successfully");
      } else {
        throw new Error(JSON.stringify(savedData.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to change status at the moment!");
    } finally {
      setIsLinkStatusToggling(false);
    }
  };

  function openEditForm(id, data) {
    showEditForm(data);
  }

  function openDuplicateForm(id, data) {
    showDuplicateForm(data);
  }

  const handleRevReportlLinkAction = (id, data) => {
    let urlWithParams = `${window.location.origin}/revenuereport?interval=${PER_DAY}&period=${LAST_SEVEN_DAYS}&group_by=publisher_id-advertiser_id-channel`;
    if (data?.link_source_identifier_value)
      urlWithParams += `&channel=${data?.link_source_identifier_value}`;
    if (data?.provider?.id)
      urlWithParams += `&advertiser_id=${data?.provider?.id}`;
    window.open(urlWithParams, "_blank");
  };

  const handleHistoryAction = (id, data) => {
    setHistoryTargId(id);
    setHistoryPage(1);
    setHistoryOpen(true);
    setStartFetchHistory(true);
  };

  const handleHistoryModalClose = () => {
    setHistoryOpen(false);
    setLatestHistoryData(null);
  };

  // Be careful while changing this because the double click feature of advertiser link uses the edit icon name to get its action
  let actions = [
    { name: "managelinks", action: openFormPage, icon: "manageIconOne" },
    {
      name: "advertiserLinks-related",
      action: onRelatedButtonClick,
      icon: "advertiserLinks-related"
    },
    {
      name: "advertiserLinks-delete",
      action: onDeleteButtonClick,
      icon: "advertiserLinks-delete"
    },
    {
      name: "editIcon",
      action: openEditForm,
      icon: "editIcon"
    },
    {
      name: "duplicateIcon",
      action: openDuplicateForm,
      icon: "duplicateIcon"
    },
    {
      name: "revenueReportLink",
      action: handleRevReportlLinkAction,
      icon: "MonetizationIcon"
    },
    {
      name: "history",
      action: handleHistoryAction,
      icon: "HistoryIcon"
    }
  ];

  return {
    headers,
    actions,
    relatedModalOpen,
    relatedModalData,
    closeRelatedModalHandler,
    handleStatusToggle,
    isLinkStatusToggling,
    warningModalOpen,
    warningModalData,
    closeWarningModalHandler,
    confirmDeleteModalOpen,
    closeConfirmDeleteModalHandler,
    onDeleteConfirmed,
    historyOpen,
    handleHistoryModalClose,
    latestHistoryData,
    isLatestHistoryLoading,
    totalHistoryCount,
    historyTargId,
    historyPage,
    setHistoryPage,
    historyLimit,
    setStartFetchHistory
  };
};
