import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useModal } from "../../../hooks/useModal";
import { TagType } from "../../../services/TagType";

export const useTagTypeTableLogic = ({
  filterValues,
  getTagTypes,
  openEditForm
}) => {
  const [archiveModalOpen, openArchiveModal, closeArchiveModal] =
    useModal(false);
  const [archiveId, setArchiveId] = useState(null);

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(false);
  const [deleteId, setDeleteId] = useState(null);

  const [restoreModalOpen, openRestoreModal, closeRestoreModal] =
    useModal(false);
  const [restoreId, setRestoreId] = useState(null);

  function handleEditAction(id) {
    openEditForm(id);
  }

  function handleArchiveAction(id) {
    setArchiveId(id);
    openArchiveModal();
  }

  async function archiveTagType() {
    try {
      const archivedTagType = await TagType.deleteTagType(archiveId);
      if (archivedTagType.success) {
        toastr.success("Success", "Traffic Type has been archived");
        getTagTypes();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(archivedTagType.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function deleteTagType() {
    try {
      const params = {
        permanent: true
      };
      const deletedTagType = await TagType.deleteTagType(deleteId, params);
      if (deletedTagType.success) {
        toastr.success("Traffic Type Deleted", "Traffic Type has been deleted");
        getTagTypes();
        handleModalClose();
      } else {
        throw new Error(JSON.stringify(deletedTagType.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
      handleModalClose();
    }
  }

  async function restoreTagType() {
    try {
      const params = {
        archive: false
      };
      const dataToSend = {};
      const restoredTagType = await TagType.updateTagType(
        restoreId,
        dataToSend,
        params
      );
      if (restoredTagType.success) {
        toastr.success("Success", "Traffic Type restored successfully");
        getTagTypes();
        handleModalClose();
      } else {
        throw new Error(restoredTagType.error);
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops", "Not able to restore traffic type");
      handleModalClose();
    }
  }

  function handlePermanaentDeleteAction(id) {
    setDeleteId(id);
    openDeleteModal();
  }

  function handleRestoreAction(id) {
    setRestoreId(id);
    openRestoreModal();
  }

  const handleModalClose = () => {
    closeArchiveModal();
    closeDeleteModal();
    closeRestoreModal();
    setArchiveId(null);
    setDeleteId(null);
    setRestoreId(null);
  };

  const headers = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" }
  ];

  let actions = [];
  if (!filterValues.archivedData) {
    actions = [
      {
        name: "edit",
        action: handleEditAction,
        icon: "editIcon"
      }
      // {
      //   name: "delete",
      //   action: handleArchiveAction,
      //   icon: "deleteIcon"
      // }
    ];
  } else {
    actions = [
      {
        name: "deleteForever",
        action: handlePermanaentDeleteAction,
        icon: "deleteForever"
      },
      {
        name: "restore",
        action: handleRestoreAction,
        icon: "restore"
      }
    ];
  }

  return {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archiveTagType,
    deleteTagType,
    restoreTagType,
    handleArchiveAction
  };
};
